import Swiper from 'swiper/bundle';

window.addEventListener('load', function () {
  const projectsCarousel = document.querySelector('.projects-block-wrapper');

  if (!projectsCarousel) {
    return;
  }
  new Swiper('.projects-block-wrapper', {
    direction: 'horizontal',
    watchOverflow: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    navigation: {
      nextEl: '.projects-block__nav-next',
      prevEl: '.projects-block__nav-prev',
    },
    pagination: {
      type: 'fraction',
      el: '.projects-block__nav-fraction',
      clickable: true,
      formatFractionCurrent(number) {
        return ('0' + number).slice(-2);
      },
      formatFractionTotal(number) {
        return ('0' + number).slice(-2);
      },
      renderFraction(currentClass, totalClass) {
        return (
          '<span class="' +
          currentClass +
          '"></span>' +
          ' / ' +
          '<span class="' +
          totalClass +
          '"></span>'
        );
      },
    },
    breakpoints: {
      200: {
        slidesPerView: 1,
        spaceBetween: 32,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
    },
  });
});
